export function getQuery(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getCookieItem(key) {
    const arrStr = document.cookie.split('; ');
    for (let i = 0; i < arrStr.length; i++) {
        const temp = arrStr[i].split('=');
        if (temp[0] === key) {
            return decodeURIComponent(temp[1]);
        }
    }
    // no this cookie
    return null;
}

export function getBrowserLanguage () {
    var language = window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage || window.navigator.systemLanguage || '';
    language = language.toLowerCase();

    if (language.indexOf('tw') >= 0 || language.indexOf('hk') >= 0) {
        return 'tw';
    }
    if (language.substr(0, 2) === 'zh') {
        return 'zh';
    }
    if (language.substr(0, 2) === 'ko') {
        return 'ko';
    }
    if (language.substr(0, 2) === 'ja') {
        return 'ja';
    }
    if (language.substr(0, 2) === 'th') {
        return 'th';
    }

    return 'en';
}
