import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import { sync } from 'vuex-router-sync';
import components from './components';
import store from './store';
import './plugins/validator/';
import './globalMixin';
// import VueVideoPlayer from 'vue-video-player';
import { getBrowserLanguage } from './utils/common';

// require videojs style
// import 'video.js/dist/video-js.css';

import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
import VueScrollactive from 'vue-scrollactive';

import './stats';

import './assets/scss/app.scss';

Vue.config.productionTip = false;
Vue.use(components);
Vue.use(VueScrollactive);

Vue.use(Toast, {
    type: 'center',
    duration: 3000,
    wordWrap: true,
    width: 'auto',
});

// Vue.use(VueVideoPlayer);

Vue.use({
    install(Vue) {
        Vue.prototype.$bus = new Vue();
    },
});

router.beforeEach((to, from, next) => {
    console.log('router.beforeEach', to, from);
    let language = to.params.lang;
    let langList = ['zh', 'tw'];
    let defaultLang = 'zh';
    if (process.env.VUE_APP_BUILD_FOR === 'evelabinsight') {
        langList = ['ja', 'ko'];
        defaultLang = 'ja';
    }
    var browserLanguage = getBrowserLanguage();
    console.log('browserLanguage = ', browserLanguage);
    if (!language) {
        if (!window.__PRERENDER_INJECTED || !window.__PRERENDER_INJECTED.isPreRender) {
            if (langList.indexOf(browserLanguage) >= 0 && browserLanguage !== defaultLang) {
                return next(`/${browserLanguage}/home`);
            } else {
                to.params.lang = defaultLang;
                language = defaultLang;
            }
        } else {
            to.params.lang = defaultLang;
            language = defaultLang;
        }
    }
    if (to.path !== '/' && (langList.indexOf(language) < 0 || !language)) {
        if (langList.indexOf(browserLanguage) >= 0) {
            language = browserLanguage;
        } else {
            language = defaultLang;
        }
        if (to.path !== '/404' || to.name !== 'page404') {
            return next(`/${language}/404`);
        }
    }

    Vue.prototype.$trackView(document.title, location.href, {
        language,
        router_name: to.name,
        router_path: to.path,
        spm: to.query && to.query.spm,
    });

    // 英文用 webflow
    if (from && from.params && from.params.lang !== 'en' && language === 'en') {
        location.href = process.env.VUE_APP_HOST;
        return;
    }

    window.currentLang = language;
    i18n.locale = language;
    next();
});

sync(store, router);

new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
