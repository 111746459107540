<template>
    <div :class="['g-ui-select', { 'is-focus': is_focus, 'is-empty': !value }]">
        <span class="text"
            ><div class="g-ellipsis g-ellipsis__1-line">{{ currentText }}</div></span
        >
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="9" height="6">
            <path fill-rule="nonzero" d="M4.5 6L0 0h9z" />
        </svg>
        <select v-model="currentValue" @focus="is_focus = true" @blur="is_focus = false">
            <template v-if="isArray(list)">
                <option value="">{{ currentPlaceholder }}</option>
                <option v-for="(item, index) in list" :key="index" :value="item.value">{{ item.text }}</option>
            </template>
            <template v-else>
                <optgroup v-for="(item, key) in list" :key="key" :label="key">
                    <option v-for="(value, area) in item" :key="value" :value="value">{{ `${area}(+${value})` }}</option>
                </optgroup>
            </template>
        </select>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: [Number, String],
        list: {
            type: [Array, Object],
            default() {
                return [];
            },
        }, // { text, value}
        placeholder: { type: String, default: '' },
    },
    data() {
        return {
            is_focus: false,
        };
    },
    computed: {
        currentPlaceholder() {
            return this.placeholder || this.$t('common.select_default');
        },
        currentValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('change', val);
            },
        },
        currentText() {
            if (this.isArray(this.list)) {
                if (!this.value) return this.currentPlaceholder;
                let find_val = this.list.find(item => item.value === this.value);
                return find_val ? find_val.text : this.currentPlaceholder;
            } else {
                if (!this.value) return '';
                return `+${this.value}`;
            }
        },
    },
    methods: {
        isArray(data) {
            return Object.prototype.toString.call(data) === '[object Array]';
        },
    },
    created() {},
};
</script>
<style lang="scss">
.g-ui-select {
    display: inline-block;
    // background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    // border-color: #000;
    position: relative;
    padding-right: 30px;
    &.is-focus {
        border-color: #000;
        z-index: 10;
    }
    &.is-empty {
        color: #999;
        .icon {
            fill: #999;
        }
    }
    .text {
        display: inline-block;
        padding: 0.6em 0.8em;
        line-height: 1;
    }
    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1em;
    }
    select {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-appearance: menulist-button; // fixed safari
    }
}
</style>
