import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

function generateAlternateMap(path) {
    var langArr = ['zh', 'zh-TW', 'zh-HK', 'en', 'ja', 'ko'];
    var obj = {};
    langArr.forEach(lang => {
        var host = lang.indexOf('zh') >= 0 ? 'https://eve.meitu.com/' : 'https://evelabinsight.com/';
        obj[lang] = `${host}${lang === 'zh-TW' || lang === 'zh-HK' ? 'tw' : lang}${path}`;
    });
    return obj;
}

export default new Router({
    mode: 'history',
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/:lang',
            component: () => import(/* webpackChunkName: "router" */ './views/index'),
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import(/* webpackChunkName: "router" */ './views/home'),
                    meta: {
                        trackName: '首页',
                        alternateMap: generateAlternateMap('/'),
                        en: {
                            keywords: 'beauty machine distributor,beauty equipment supplier,ai skin tech,ai beauty system solution,ai beauty solution',
                            title: 'AI Beauty Machine Distributor & Skin Tech Equipment Supplier',
                            description: 'As a reliable AI beauty machine supplier, we offer advanced beauty solutions for your spa, clinic, or store. Explore our AI beauty systems and equipment today.',
                        },
                    },
                },
                {
                    path: 'home',
                    redirect: {
                        name: 'home',
                    },
                },
                {
                    path: 'index',
                    redirect: {
                        name: 'home',
                    },
                },
                {
                    path: 'product',
                    name: 'product',
                    component: () => import(/* webpackChunkName: "router" */ './views/product'),
                    children: [
                        {
                            path: '/',
                            name: 'productHome',
                            component: () => import(/* webpackChunkName: "product" */ './views/product/home/'),
                            meta: {
                                alternateMap: generateAlternateMap('/product'),
                                en: {
                                    title: 'Products | EveLab Insight',
                                    description: 'EveLab Insight - Your source for high-tech beauty solutions. Explore AI-driven skin analysis devices & diagnostic tools, setting trends in skincare technology.',
                                },
                            },
                        },
                        {
                            path: 'index/',
                            redirect: {
                                name: 'productHome',
                            },
                        },
                        {
                            path: 'eve/',
                            name: 'productEve',
                            meta: {
                                subMenuNav: 'skin',
                                alternateMap: generateAlternateMap('/product/eve'),
                                en: {
                                    title: 'Eve | EveLab Insight',
                                    description: 'Eve M: Revolutionize skincare with our advanced skin analysis device. Discover cutting-edge AI technology for precise diagnostics and tailored solutions.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/eve/'),
                        },
                        {
                            path: 'evev/',
                            name: 'productEveV',
                            meta: {
                                subMenuNav: 'skin',
                                alternateMap: generateAlternateMap('/product/evev'),
                                en: {
                                    keywords: 'skin analysis machine,skin analysis device,skin diagnostic machine,skin diagnosis machine',
                                    title: 'Skin Diagnosis & Analysis Machine for Brands, Salons & Clinics',
                                    description: 'Elevate your skincare services with Eve V skin analysis machine. Learn more about how our skin diagnosis device can benefit your brand.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/eveV/'),
                        },
                        {
                            path: 'skin-mirror/',
                            name: 'productEveskin',
                            meta: {
                                subMenuNav: 'skin',
                                alternateMap: generateAlternateMap('/product/skin-mirror'),
                                en: {
                                    keywords: 'digital skin analysis,skin analysis tool,skin analysis system,ai skin analysis,skincare ai',
                                    title: 'AI Skin Analysis System for Websites, Digital Apps & More',
                                    description: 'Tailored skincare recommendations with EveLab’s AI Skin Analysis System. Learn how this EveLabs’ AI tool can support customers, stores, and brands.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/skinMirror/'),
                        },
                        {
                            path: 'evekey/',
                            name: 'productMeitukey',
                            meta: {
                                subMenuNav: 'skin',
                                alternateMap: generateAlternateMap('/product/evekey'),
                                en: {
                                    title: 'Eve Key | EveLab Insight',
                                    description: 'Eve Key Skin Analysis Device: Precision sub-area skin diagnosis with EveLab Insight\'s AI algorithm. Enhance your skincare routine effortlessly.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/meitukey/'),
                        },
                        {
                            path: 'meitukey/',
                            redirect: {
                                name: 'productMeitukey',
                            },
                        },
                        {
                            path: 'virtual-makeup/',
                            name: 'productMakeup',
                            meta: {
                                subMenuNav: 'makeup',
                                alternateMap: generateAlternateMap('/product/virtual-makeup'),
                                en: {
                                    title: 'Virutal Makeup Technology | EveLab Insight',
                                    description: 'Experience virtual makeup trials with stability and ease. Our online solution offers a seamless experience for customers seeking virtual beauty experiences.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/makeup/'),
                        },
                        {
                            path: 'virtual-hair-dye/',
                            name: 'productHair',
                            meta: {
                                subMenuNav: 'makeup',
                                alternateMap: generateAlternateMap('/product/virtual-hair-dye'),
                                en: {
                                    title: 'Virtual Hair Dye Technology | EveLab Insight',
                                    description: 'Transform your beauty tech experience with our virtual hair dye trials. Explore rich colors and stable results online or in-store.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/hair/'),
                        },
                        {
                            path: 'accessories/',
                            name: 'productAccessories',
                            meta: {
                                subMenuNav: 'makeup',
                                alternateMap: generateAlternateMap('/product/accessories'),
                                en: {
                                    title: 'Accessories | EveLab Insight',
                                    description: 'Discover our beauty tech solution: virtual jewelry trial services! Customize hats, eyeglasses, and more. Available on web, app, and mini-program.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/accessories/'),
                        },
                        {
                            path: 'contact-lenses/',
                            name: 'productContactLenses',
                            meta: {
                                subMenuNav: 'makeup',
                                alternateMap: generateAlternateMap('/product/contact-lenses'),
                                en: {
                                    title: 'Contact Lens Brands Technology | EveLab Insight',
                                    description: 'Experience the future of contact lens shopping with our innovative virtual trial service. Try before you buy, whether at stores or online platforms.',
                                },
                            },
                            component: () => import(/* webpackChunkName: "product" */ './views/product/contactLenses/'),
                        },
                        {
                            path: '/',
                            redirect: {
                                name: 'productHome',
                            },
                        },
                    ],
                },
                {
                    path: 'solutions',
                    name: 'solutions',
                    component: () => import(/* webpackChunkName: "solutions" */ './views/solutions'),
                    meta: {
                        alternateMap: generateAlternateMap('/solutions'),
                        en: {
                            title: 'Solutions | EveLab Insight',
                            description: 'Elevate your beauty business with tailored solutions - Our Skin Analysis Service & Virtual Try-on embed seamlessly into your app, website, or third-party platform.',
                        },
                    },
                },
                {
                    path: 'success-case',
                    name: 'successCase',
                    component: () => import(/* webpackChunkName: "successCase" */ './views/successCase'),
                    meta: {
                        alternateMap: generateAlternateMap('/success-case'),
                        en: {
                            title: 'Case Studies | EveLab Insight',
                            description: 'Explore success stories and case studies showcasing the effectiveness of our skin analysis solutions.',
                        },
                    },
                },
                {
                    path: 'news',
                    name: 'news',
                    component: () => import(/* webpackChunkName: "news" */ './views/news'),
                    meta: {
                        alternateMap: generateAlternateMap('/news'),
                        en: {
                            title: 'News | EveLab Insight',
                            description: 'Stay updated with the latest news and developments from EveLab Insight. Discover groundbreaking innovations and industry insights on our news page.',
                        },
                    },
                },
                {
                    path: 'news/:id?',
                    name: 'newsDetail',
                    component: () => import(/* webpackChunkName: "news" */ './views/news/detail'),
                },
                {
                    path: 'qa',
                    name: 'qa',
                    redirect: {
                        name: 'qaInfo',
                    },
                    component: () => import(/* webpackChunkName: "qa" */ './views/qa'),
                    children: [
                        {
                            path: 'info/',
                            name: 'qaInfo',
                            component: () => import(/* webpackChunkName: "qa" */ './views/qa/info/'),
                            meta: {
                                alternateMap: generateAlternateMap('/qa/info'),
                                en: {
                                    title: 'Pre Sales & After Sales Enquiries | FAQ | EveLab Insight',
                                    description: ' Your go-to resource for skin analysis machine inquiries. Find answers to common questions, ensuring smooth pre- and post-purchase experiences.',
                                },
                            },
                        },
                        {
                            path: 'tech-specs',
                            name: 'techSpecs',
                            redirect: {
                                name: 'techSpecsEveV',
                            },
                            component: () => import(/* webpackChunkName: "qa" */ './views/qa/techSpecs/'),
                            children: [
                                {
                                    path: 'evev/',
                                    name: 'techSpecsEveV',
                                    component: () => import(/* webpackChunkName: "qa" */ './views/qa/techSpecs/eveV.vue'),
                                    meta: {
                                        alternateMap: generateAlternateMap('/qa/tech-specs/evev'),
                                        en: {
                                            title: 'Eve V Technical Specifications | EveLab Insight',
                                            description: 'Eve V Tech Specs - Discover the innovative features of our state-of-the-art skin analysis device, powered by advanced AI technology for next-level beauty solutions.',
                                        },
                                    },
                                },
                                {
                                    path: 'eve/',
                                    name: 'techSpecsEveM',
                                    component: () => import(/* webpackChunkName: "qa" */ './views/qa/techSpecs/eveM.vue'),
                                    meta: {
                                        alternateMap: generateAlternateMap('/qa/tech-specs/eve'),
                                        en: {
                                            title: 'Eve M Technical Specifications | EveLab Insight',
                                            description: 'Eve M Tech Specs - Explore the capabilities of our cutting-edge skin analysis machine. Powered by AI technology, it\'s the ultimate beauty tech solution.',
                                        },
                                    },
                                },
                                {
                                    path: 'evekey/',
                                    name: 'techSpecsMeitukey',
                                    component: () => import(/* webpackChunkName: "qa" */ './views/qa/techSpecs/meitukey.vue'),
                                    meta: {
                                        alternateMap: generateAlternateMap('/qa/tech-specs/evekey'),
                                        en: {
                                            title: 'Eve Key Technical Specifications | EveLab Insight',
                                            description: 'Eve Key Tech Specs - Explore the cutting-edge features of our skin analysis device. Powered by AI skin tech, it\'s your ultimate beauty solution.',
                                        },
                                    },
                                },
                                {
                                    path: 'eveV/',
                                    redirect: {
                                        name: 'techSpecsEveV',
                                    },
                                },
                                {
                                    path: 'eveM/',
                                    redirect: {
                                        name: 'techSpecsEveM',
                                    },
                                },
                                {
                                    path: 'meitukey/',
                                    redirect: {
                                        name: 'techSpecsMeitukey',
                                    },
                                },
                                {
                                    path: 'genius/',
                                    name: 'techSpecsGenius',
                                    component: () => import(/* webpackChunkName: "qa" */ './views/qa/techSpecs/genius.vue'),
                                    meta: {
                                        alternateMap: generateAlternateMap('/qa/tech-specs/genius'),
                                        en: {
                                            title: 'Genius Technical Specifications | EveLab Insight',
                                            description: 'Unlock the power of Genius Virtual Try-On technology. Discover specifications driving innovative beauty solutions for an enhanced customer experience.',
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            path: 'download/',
                            name: 'qaDownload',
                            component: () => import(/* webpackChunkName: "qa" */ './views/qa/download/'),
                            meta: {
                                alternateMap: generateAlternateMap('/qa/download'),
                                en: {
                                    title: 'Product User Manuals | EveLab Insight',
                                    description: 'Access resources for our innovative skin analysis devices. Explore manuals, software updates, and more for optimal performance.',
                                },
                            },
                        },
                        {
                            path: 'evekey/',
                            name: 'qaMeitukey',
                            component: () => import(/* webpackChunkName: "qameitukey" */ './views/qa/meitukey/'),
                            meta: {
                                alternateMap: generateAlternateMap('/qa/evekey'),
                                en: {
                                    title: 'Eve Key Guide | EveLab Insight',
                                    description: 'Get answers to all your questions about Eve Key Skin Analysis Device. Explore FAQs for insights on this advanced skin analysis technology and its applications',
                                },
                            },
                        },
                        {
                            path: 'meitukey/',
                            redirect: {
                                name: 'qaMeitukey',
                            },
                        },
                    ],
                },
                {
                    path: 'contact',
                    name: 'contact',
                    component: () => import(/* webpackChunkName: "contact" */ './views/contact'),
                    meta: {
                        alternateMap: generateAlternateMap('/contact'),
                        en: {
                            title: 'Contact Us | EveLab Insight',
                            description: 'Contact us and find our office addresses for inquiries or collaboration opportunities. Reach out to our global team for personalized support and solutions.',
                        },
                    },
                },
                {
                    path: 'aboutus/',
                    name: 'aboutus',
                    redirect: {
                        name: 'aboutusInfo',
                    },
                    component: () => import(/* webpackChunkName: "aboutus" */ './views/aboutus/'),
                    children: [
                        {
                            path: 'info/',
                            name: 'aboutusInfo',
                            component: () => import(/* webpackChunkName: "aboutus" */ './views/aboutus/info/'),
                            meta: {
                                alternateMap: generateAlternateMap('/aboutus'),
                                en: {
                                    title: 'About Us | EveLab Insight',
                                    description: 'Pioneering skin analysis and facial image processing algorithms awarded multiple global patents and prizes. Leading the industry with innovation.',
                                },
                            },
                        },
                    ],
                },
                {
                    path: '404',
                    name: 'pageNotFound',
                    component: () => import(/* webpackChunkName: "router" */ './views/404'),
                    meta: {
                        alternateMap: generateAlternateMap('/404'),
                    },
                },
                {
                    path: '*',
                    redirect: {
                        name: 'pageNotFound',
                    },
                },
            ],
        },
        {
            path: '/',
            component: () => import(/* webpackChunkName: "router" */ './views/index'),
            children: [
                {
                    path: '',
                    name: 'g-home',
                    meta: {
                        trackName: '首页',
                        alternateMap: generateAlternateMap('/home'),
                        en: {
                            keywords: 'beauty machine distributor,beauty equipment supplier,ai skin tech,ai beauty system solution,ai beauty solution',
                            title: 'AI Beauty Machine Distributor & Skin Tech Equipment Supplier',
                            description: 'As a reliable AI beauty machine supplier, we offer advanced beauty solutions for your spa, clinic, or store. Explore our AI beauty systems and equipment today.',
                        },
                    },
                    component: () => import(/* webpackChunkName: "router" */ './views/home'),
                },
            ],
        },
        {
            path: '*',
            redirect: {
                name: 'pageNotFound',
            },
        },
    ],
});
