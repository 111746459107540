import localeEn from 'vee-validate/dist/locale/en.js';
import localeZh from 'vee-validate/dist/locale/zh_CN';
import localeKo from 'vee-validate/dist/locale/ko';
import localeTw from 'vee-validate/dist/locale/zh_TW';
import localeJa from 'vee-validate/dist/locale/ja';

/*eslint-disable */
const custom = {
    en: {
        messages: {
            phone_email: (field) => `Please enter valid ${field}`,
            phone_cc_right: () => 'Please enter the correct area code',
        },
    },
    zh: {
        messages: {
            phone_email: (field) => `请填写正确的${field}`,
            phone_cc_right: () => '请输入正确的区号数字',
        },
    },
    ko: {
        messages: {
            phone_email: (field) => `유효한 ${field}을/를 입력해 주세요`,
            phone_cc_right: () => '정확한 지역 코드를 입력해 주세요',
        },
    },
    tw: {
        messages: {
            phone_email: (field) => `請填寫正確的${field}`,
            phone_cc_right: () => '請輸入正確的區號數字',
        },
    },
    ja: {
        messages: {
            phone_email: (field) => `正しい${field}を入力してください`,
            phone_cc_right: () => '正しい市外局番を入力してください',
        },
    },
};

export default {
    en: { messages: { ...localeEn.messages, ...custom.en.messages } },
    zh: { messages: { ...localeZh.messages, ...custom.zh.messages } },
    ko: { messages: { ...localeKo.messages, ...custom.ko.messages } },
    tw: { messages: { ...localeTw.messages, ...custom.tw.messages } },
    ja: { messages: { ...localeJa.messages, ...custom.ja.messages } },
};
/*eslint-enable */
