<template>
    <div class="g-video">
        <video :poster="poster" class="js-autoplay-video" x5-video-orientation="portraint" x5-video-player-fullscreen="false" x-webkit-airplay="allow" x5-playsinline="" webkit-playsinline="true" playsinline="true" preload="meta" :muted="muted" :controls="controls" :loop="loop">
            <source :src="src" type="video/mp4" />
        </video>
        <div class="g-video-button" @click="videoPlay()" v-show="!isPlaying">
            <slot name="button">
                <svg v-show="!isPlaying" xmlns="http://www.w3.org/2000/svg" width="41" height="40">
                    <g fill="none" fill-rule="nonzero" transform="translate(.813 .627)">
                        <rect width="39.312" height="39.312" fill="#000" opacity=".77" rx="19.656" />
                        <path fill="#FFF" d="M26.732 19.656l-10.221 6.683V12.973z" />
                    </g>
                </svg>
            </slot>
        </div>
        <!-- <div v-slot:button>
            <svg class="g-video-button__play"  xmlns="http://www.w3.org/2000/svg" width="41" height="40">
                <g fill="none" fill-rule="nonzero" transform="translate(.813 .627)">
                    <rect width="39.312" height="39.312" fill="#000" opacity=".77" rx="19.656" />
                    <path fill="#FFF" d="M26.732 19.656l-10.221 6.683V12.973z" />
                </g>
            </svg>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        src: { type: String, default: '' },
        poster: { type: String, default: '' },
        muted: { type: Boolean, default: true },
        controls: { type: Boolean, default: false },
        loop: { type: Boolean, default: true },
    },
    data() {
        return {
            video: null,
            isPlaying: false,
        };
    },
    mounted() {
        this.video = this.$el.querySelector('video');

        ['timeupdate', 'playing', 'play'].forEach(name => {
            this.video.addEventListener(name, this.onPlay);
        });
        this.video.addEventListener('pause', this.onPause);
    },
    beforeDestroy() {
        ['timeupdate', 'playing', 'play'].forEach(name => {
            this.video.removeEventListener(name, this.onPlay);
        });
        this.video.removeEventListener('pause', this.onPause);
    },
    methods: {
        videoPlay() {
            this.isPlaying = true;
            this.video.play();
        },
        onPlay() {
            if (this.video.currentTime > 0) {
                this.isPlaying = true;
            }
        },
        onPause() {
            if (this.loop) {
                this.isPlaying = false;
            } else {
                if (this.video.currentTime !== this.video.duration) {
                    this.isPlaying = false;
                } else {
                    this.isPlaying = true;
                }
            }
        },
    },
};
</script>

<style lang="scss">
.g-video {
    position: relative;
    &-button {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
