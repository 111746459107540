import Vue from 'vue';
import { getQuery } from './utils/common';

if (process.env.VUE_APP_BUILD_FOR !== 'evelabinsight') {
    const app_key = process.env.VUE_APP_STATS_APP_KEY;
    const env = process.env.VUE_APP_STATS_ENV;

    if (app_key && window.mtstat) {
        window.mtstat.init({
            app_version: '1.0.0',
            app_language: 'zh',
            debug: false,
            app_key,
            env,
            log_level: 0,
        });
    }
}

/**
 * @description: Google Analytics(gtag.js) 事件统计
 * @param {String} event_id 事件名称
 * @param {Object} params 事件参数，自定义指标
 * @return {void}
 */

function track(event_id, options = {}) {
    const referrer = document.referrer;
    // 公共参数
    var common = {
        spm: getQuery('spm') || getQuery('source') || getQuery('utm_source') || '',
    };
    var opts = {
        ...common,
        ...options,
        entry: '',
        entry_referrer: '',
        page_url: location.href,
    };
    if (referrer) {
        const match = referrer.match(/^https?:\/\/([^\/]*)\/.*$/);
        opts.entry = match && match[1];
        opts.entry_referrer = referrer;
    }
    console.log(`[track ${event_id}] - `, opts);
    if (window.mtstat) {
        window.mtstat.track(event_id, opts);
    }
    if (window.gtag) {
        window.gtag('event', event_id, {
            event_category: 'Eve',
            event_label: 'event',
            params: Object.keys(opts).length ? JSON.stringify(opts) : '',
        });
    }
}

/**
 * @description: Google Analytics(gtag.js) 网页浏览量
 * @param {String} title 页面标题
 * @param {String} path 页面路径
 * @param {Object} params 事件参数，自定义指标
 * @return {void}
 */
function trackView(title, path, params = {}) {
    const referrer = document.referrer;
    if (referrer) {
        const match = referrer.match(/^https?:\/\/([^\/]*)\/.*$/);
        params.entry = match && match[1];
        params.entry_referrer = referrer;
    } else {
        params.entry = '';
        params.entry_referrer = '';
    }
    params.spm = getQuery('spm') || getQuery('source') || '';
    if (window.mtstat) {
        window.mtstat.pageview({
            title: title,
            page_url: path,
            ...params,
        });
    }
    if (window.gtag) {
        window.gtag('event', 'page_view', {
            page_title: title,
            page_location: path,
            page_path: path,
            params: Object.keys(params).length ? JSON.stringify(params) : '',
        });
    }
}
Vue.use({
    install(Vue) {
        Vue.prototype.$track = track;
        Vue.prototype.$trackView = trackView;
    },
});
