import Vue from 'vue';
import i18n from '../../i18n';

import VeeValidate, { Validator } from 'vee-validate';
import local from './local';
// import message_en from 'vee-validate/dist/locale/en';
// import message_zh from 'vee-validate/dist/locale/zh_CN';
// import message_tw from 'vee-validate/dist/locale/zh_TW';
// import message_ja from 'vee-validate/dist/locale/ja';
// 手机号码
Validator.extend('phone', (value) => {
    return /^\d{6,18}$/.test(value)
}, {});
Validator.extend('phone_email', (value) => {
    return /^\d+$/.test(value) || /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value);
}, {});
Validator.extend('phone_cc_right', (val) => {
    const lang = window.currentLang;
    const json = require('@/utils/area-phone-code.json');
    const phoneCodeList = json[lang] || json.en;
    const codeMap = {};
    Object.keys(phoneCodeList).forEach(char => {
        const charMap = phoneCodeList[char];
        Object.keys(charMap).forEach(text => {
            codeMap[charMap[text]] = text;
        });
    });
    const newVal = String(val).replace('+', '');
    console.log('phone_cc_right', newVal, codeMap[newVal])
    return !!codeMap[newVal];
}, {});

Vue.use(VeeValidate, {
    errorBagName: 'vee_errors',
    fieldsBagName: 'vee_fields',
    delay: 0,
    i18n,
    locale: 'en', // the default lang
    dictionary: local,
    // dictionary: {
    //     en: message_en,
    //     zh: message_zh,
    //     tw: message_tw,
    //     ja: message_ja,
    // },
    strict: true,
    enableAutoClasses: false,
    classNames: {
        touched: 'touched', // the control has been blurred
        untouched: 'untouched', // the control hasn't been blurred
        valid: 'valid', // model is valid
        invalid: 'invalid', // model is invalid
        pristine: 'pristine', // control has not been interacted with
        dirty: 'dirty', // control has been interacted with
    },
    inject: false,
});

export default Validator;
