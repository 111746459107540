import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        if (/^(\.\/)[A-Za-z0-9]+\/[A-Za-z0-9]+\.json$/ig.test(key)) { // 第一层目录下的所有 json 文件
            const dirName = (key.replace('./', '').match(/^[A-Za-z0-9]+/i) || [])[0]; // 目录如news
            const locale = (key.match(/[A-Za-z0-9]+\.json$/i) || [])[0].replace('.json', ''); // 文件名如zh、en
            if (dirName && locale) {
                // console.log('----key', key, dirName, locale)
                messages[locale] = {
                    ...messages[locale],
                    [dirName]: locales(key)
                };
            }
        } else {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                messages[locale] = {
                    ...messages[locale],
                    ...locales(key),
                };
            }
        }
    });
    return messages;
}

export const langList = [
    {
        id: 'zh',
        name: '简体中文',
    },
    {
        id: 'tw',
        name: '繁體中文',
    },
    {
        id: 'en',
        name: 'English',
    },
    {
        id: 'ja',
        name: '日本語',
    },
    {
        id: 'ko',
        name: '한국어',
    },
];
export default new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
});
