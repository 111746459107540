import Vue from 'vue';
import Vuex from 'vuex';
import { langList } from '../i18n/';

Vue.use(Vuex);
export default new Vuex.Store({
    state: () => ({
        langList,
    }),
    getters: {},
    mutations: {
        SET_LANG(state, payload) {
            window.currentLang = payload;
            state.currentLang = payload;
        },
    },
    actions: {},
});
