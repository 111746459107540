import Vue from 'vue';
import { mapState } from 'vuex';

Vue.mixin({
    data() {
        return {
            scrollMagicObj: {},
            mxPageStartTime: 0,
        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
    },
    beforeDestroy() {
        for (var prop in this.scrollMagicObj) {
            if (this.scrollMagicObj[prop]) {
                if (this.scrollMagicObj[prop].destroy) {
                    this.scrollMagicObj[prop].destroy();
                }
                delete this.scrollMagicObj[prop];
            }
        }
        this.scrollMagicObj = {};

        if (this.pageDurationID) {
            this.$track('overseas_web_page_view', {
                page: this.pageDurationID,
                duration: +new Date() - this.mxPageStartTime,
            });
        }
    },
    methods: {
        mxShowContactModal(params) {
            this.$bus.$emit('contact-modal', params);
        },
        mxAnimation(options) {
            if (window.__PRERENDER_INJECTED) return;
            TweenMax.set(options.handle, {
                y: options.y || 100,
                autoAlpha: options.autoAlpha || 0,
            });
            var controller = new ScrollMagic.Controller();
            var tm = new TimelineMax();
            tm.staggerTo(
                options.handle,
                options.duration || 0.6,
                {
                    y: 0,
                    autoAlpha: 1,
                },
                options.interval || 0.2
            );
            new ScrollMagic.Scene({
                triggerElement: options.triggerElement,
                triggerHook: options.triggerHook || 0.65,
            })
                .on('enter', () => {
                    options.onEnter && options.onEnter();
                })
                .setTween(tm)
                .addTo(controller);
        },
        mxScrollToCenter(data) {
            let winW = window.innerWidth;
            let tabsWrapEl = data.scrollEl;
            let current = data.el;
            if (!tabsWrapEl || !current) return;
            if (winW <= 768) {
                tabsWrapEl.scrollTo({
                    left: current.offsetLeft + (data.offset || 0),
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
    },
    mounted() {
        this.mxPageStartTime = +new Date();
    },
});
