import col from './col';
import row from './row';
import select from './ui/select';
import video from './ui/video';

const components = {
    col,
    row,
    select,
    video,
};

export default {
    install(Vue) {
        Object.keys(components).forEach(name => name && Vue.component(`g-${name}`, components[name]));
    },
};
